require('normalize.css');
require('../../assets/css/main.scss');
require('./index.scss');

import btdCaseBg from './images/bornthisday.jpg';
import btdLogo from './images/bornthisday.svg';
import lendoCaseBg from './images/lendo-case-bg.svg';
import lendoLogo from './images/lendo.svg';
import compricerCaseBg from './images/compricer-case-bg.webp';
import compricerLogo from './images/compricer.svg';
import roiCaseBg from './images/roi-sweden.jpg';
import roiLogo from './images/roi-sweden.png';
import faCaseBg from './images/framgangsakademin-case-bg.svg';
import faLogo from './images/framgangsakademin.svg';
import aaCaseBg from './images/agency-academy.jpg';
import aaLogo from './images/agency-academy.png';
import enigioCaseBg from './images/enigio-case-bg.png';
import enigioLogo from './images/enigio.svg';
import mybankerCaseBg from './images/mybanker-case-bg.png';
import mybankerLogo from './images/mybanker.svg';

document.getElementById('bornThisDayLogo').src = btdLogo;
document.getElementById('lendoLogo').src = lendoLogo;
document.getElementById('compricerLogo').src = compricerLogo;
document.getElementById('roiSwedenLogo').src = roiLogo;
document.getElementById('framgangsakademinLogo').src = faLogo;
document.getElementById('agencyAcademyLogo').src = aaLogo;
document.getElementById('enigioLogo').src = enigioLogo;
document.getElementById('mybankerLogo').src = mybankerLogo;

document.getElementById('bornThisDay').style.backgroundImage = `url(${btdCaseBg})`;
document.getElementById('lendo').style.backgroundImage = `url(${lendoCaseBg})`;
document.getElementById('compricer').style.backgroundImage = `url(${compricerCaseBg})`;
document.getElementById('roiSweden').style.backgroundImage = `url(${roiCaseBg})`;
document.getElementById('framgangsakademin').style.backgroundImage = `url(${faCaseBg})`;
document.getElementById('agencyAcademy').style.backgroundImage = `url(${aaCaseBg})`;
document.getElementById('enigio').style.backgroundImage = `url(${enigioCaseBg})`;
document.getElementById('mybanker').style.backgroundImage = `url(${mybankerCaseBg})`;

